import styled from 'styled-components'
import { Icon } from '../icon/icon'
import { irem, rem } from '../../utils'
import Link from 'next/link'

export const StyledGridLinkIcon = styled(Icon)`
  color: ${props => props.theme.palette.secondary.dark};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  transition: transform 0.2s ease;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(18)};
  }
`

export const StyledLinksItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${props => props.theme.typography.pxToRem(4)};

  > a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    transition: color 0.1s ease-in-out;
    color: ${props => props.theme.palette.text.primary};
    padding: ${props => rem(11, props.theme)} ${props => rem(30, props.theme)} ${props => rem(10, props.theme)} ${props => rem(30, props.theme)};

    ${props => props.theme.breakpoints.up('sm')} {
      font-size: ${irem(22)};
      padding: ${props => rem(23, props.theme)} ${props => rem(22, props.theme)} ${props => rem(22, props.theme)} ${props => rem(34, props.theme)};
    }
  }

  &:hover {
    > a {
      color: ${props => props.theme.palette.secondary.dark};

      > ${StyledGridLinkIcon} {
        transform: translateX(${props => props.theme.typography.pxToRem(-5)});
      }
    }
  }
`

export const StyledGridLinkTitle = styled(Link)`
  position: relative;
  top: 1px;
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(18)};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  transition: color 0.2s ease;
  text-decoration: none;
  margin: auto 0;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(22)};
  }
`

export const StyledLinkGridWrapper = styled.div<{$colNumber?: number; $dark?: boolean}>`
  display: grid;
  grid-template-columns: repeat(${props => props.$colNumber}, 1fr);
  grid-gap: ${props => props.theme.typography.pxToRem(12)};

  ${props=> props.theme.breakpoints.up('sm')} {
    grid-gap: ${props => props.theme.typography.pxToRem(26)} ${props => props.theme.typography.pxToRem(48)};
  }

  ${StyledLinksItem} {
    background: ${props => props.$dark ? '#f2f2f2' : props.theme.palette.common.white};
  }
`