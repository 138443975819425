/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { PropsWithChildren, useMemo } from 'react'
import { LinkProps } from '../link/link'
import { StyledGridLinkIcon, StyledGridLinkTitle, StyledLinkGridWrapper, StyledLinksItem } from './StyledLinkGrid'

export type LinkGridContainerProps = {
  //
}

export type LinkGridWrapperProps = {
  colNumber?: number
  dark: boolean
}

export type LinkWrapperProps = {
  //
}

export type LinkGridElementProps = { linkWrapper?: boolean; link?: boolean; href?: string; variant?: 'dark' | 'light'; target?: string} & (

  // Link item wrapper
  | ({ linkWrapper: true; link?: false; href?: never; variant?: string; onClick?: () => void; infoClass?: string } & LinkWrapperProps)
  // Link item
  | ({ linkWrapper?: false; link: true; href: string; variant: string; onClick: () => void; infoClass: string } & LinkProps)
  // Container
  | ({ linkWrapper?: false; link?: true; href?: string; variant?: string; onClick?: () => void; infoClass?: string } & LinkGridContainerProps)
)

export const LinkGrid: React.FC<PropsWithChildren<LinkGridElementProps>> = ({ linkWrapper, link, href, variant, infoClass, onClick, target, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const col = useMemo(() => {
    if (isMobile) {
      return 1
    }

    if (isTablet) {
      return 2
    }

    return 3
  }, [isMobile, isTablet])

  if (link) {
    return (
      // @ts-ignore
      <StyledGridLinkTitle href={ onClick ? '#' : (href || '#')} {...props} passHref legacyBehavior>
        {/* @ts-ignore */}
        <a className={infoClass} target={target} onClick={onClick}>
          {props.children}
          <StyledGridLinkIcon icon={faChevronRight}/>
        </a>
      </StyledGridLinkTitle>
    )
  }

  if (linkWrapper) {
    return (
      <LinkWrapper {...props}>
        {props.children}
      </LinkWrapper>
    )
  }

  return (
    <LinkGridContainer {...props} colNumber={col} dark={variant==='dark'}>
      {props.children}
    </LinkGridContainer>
  )
}

const LinkGridContainer: React.FC<PropsWithChildren<LinkGridWrapperProps>> = ({ colNumber, children, dark,  ...props }) => (
  <StyledLinkGridWrapper {...props} $colNumber={colNumber} $dark={dark}>
    {children}
  </StyledLinkGridWrapper>
)

const LinkWrapper: React.FC<PropsWithChildren<LinkWrapperProps>> = ({ children, ...props }) => (
  <StyledLinksItem {...props}>
    {children}
  </StyledLinksItem>
)
